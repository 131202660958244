<script lang="tsx">
import { defineComponentCoreIcon } from '@core/app/components/core/icon/CoreIcon.vue'

export default defineComponentCoreIcon(
    <svg viewBox="0 0 31 31" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M19.5295 1.15894c5.5515.62573 9.9375 5.07133 10.56 10.70596M19.5295 6.55298c2.6565.52373 4.7325 2.63234 5.25 5.32862"
            stroke="currentColor"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path d="M14.5473 16.3416c5.9835 6.0715 7.3409-.9526 11.1506 2.9116 3.6728 3.7269 5.7854 4.4736 1.1303 9.1955-.5829.4755-4.2863 6.1957-17.3015-7.011C-3.49011 8.22927 2.14235 4.46641 2.61092 3.87494 7.27565-.860088 8.00023 1.29502 11.6731 5.02193 15.4812 8.88772 8.5638 10.27 14.5473 16.3416Z"
            clip-rule="evenodd"
            stroke="currentColor"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
    </svg>
)
</script>

<style lang="scss" scoped>

</style>
